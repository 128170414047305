<template>
  <div class="page">
    <w-image tag="img" width="100%" class="bdrs1 mt8" src="/image/page/service/nagasa-mai/takeout/cover_v0.2.0.jpg"></w-image>
    <div class="content">
      <p>
        &emsp;古来より、この土地で育つ稲が美味しいお米を実らせる理由は、平野の地形に潜んだ３つの<b>ひみつ</b>にあるんです。
        <br>&emsp;<w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/secret/">美味しく育つひみつ</router-link>
      </p>
      <w-image tag="img" width="100%" class="bdrs1 mt8" src="/image/page/service/nagasa-mai/takeout/map_v0.2.0.jpg"></w-image>
      <p>
        <br>&emsp;お米屋さんを通して、混ぜ合わされた味を提供するのではなく、
        <b>自慢の味</b>をそのままのかたちで、<b>安く</b>お客様にお渡ししたいと考えております。
        <br>&emsp;<w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/detail/#natural">本来の味をお渡ししたい</router-link>
        <br>&emsp;<w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/service/nagasa-mai/detail/#low-cost">本当に安い？</router-link>
      </p>
      <p>
        <w-flex class="justify-space-evenly  align-center">
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs5" src="/image/page/service/nagasa-mai/takeout/nagasa-mai_v0.1.1.jpg"></w-image>
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs5" src="/image/page/service/nagasa-mai/takeout/genmai-30kg_v0.1.2.jpg"></w-image>
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs5" src="/image/page/service/nagasa-mai/takeout/cool_v0.1.1.jpg"></w-image>
        </w-flex>
      </p>
      <h2>
        １．<w-icon>mdi mdi-credit-card-outline</w-icon>
        スマホで簡単決済
      </h2>
      <w-divider/>
      <p>
        &emsp;
        <a target="_blank" rel="noopener noreferrer" href="https://jmty.jp/chiba">ジモティー掲示板</a>
        サイトの「
        <a target="_blank" rel="noopener noreferrer" href="https://jmty.jp/about/ec">あんしん決済</a>
        」で、お支払いいただけます。
      </p>
      <h2>
        ２．<w-icon>mdi mdi-calendar-multiselect</w-icon>
        ご来場の予約
      </h2>
      <w-divider/>
      <p>
        &emsp;ご購入いただきましたら、
        取りに来られる際の<b>住所</b>・<b>道案内の地図</b>・<b>ピックアップ方法</b>をメッセージにてお知らせいたします。
        商品は１ヶ月ほど保冷コンテナにて保管しておきますので、取りに来られる日時が決まりましたら、
        ３０時間ほど前までに<b>ピックアップ予定日時</b>をメッセージにてお知らせください。
      </p>
      <h2>
        ３．<w-icon>mdi mdi-car</w-icon>
        セルフでピックアップ
      </h2>
      <w-divider/>
      <p>
        &emsp;お知らせいただいたピックアップ予定日時の数時間前に、<b>ピックアップスペース</b>（テニスコートほどの広場）に
        「<b>○○様 お持ち帰り用</b>」と表記した商品（玄米３０キロ袋入り）を準備いたしますので、
        お車でご来場されて、ご自分でピックアップされて、お気をつけてお持ち帰りになってください。
      </p>
      <p>
        <w-flex class="justify-space-evenly  align-center">
          <w-image tag="img" width="100%" style="max-width: 500px" class="bdrs5" src="/image/page/service/nagasa-mai/takeout/present_v0.1.0.jpg"></w-image>
        </w-flex>
      </p>
      <p>
        <ul class="no-point">
          <li><w-icon>mdi mdi-check-bold</w-icon> 常に一番新鮮なお米（稲刈り直後に新米へ切り替え）をご提供しています</li>
          <li><w-icon>mdi mdi-check-bold</w-icon> 玄米専用の保冷庫で、いつまでも美味しく保冷しています</li>
          <li><w-icon>mdi mdi-check-bold</w-icon> ぜひ、<router-link to="/service/nagasa-mai/detail/#actual">実際の稲とお米の様子</router-link>をご覧になってください</li>
          <li><w-icon>mdi mdi-check-bold</w-icon> 道案内の地図は、交差点の写真とGoogleマイマップの共有にて行っております</li>
          <li><w-icon>mdi mdi-check-bold</w-icon> マイマップには、最寄りのコイン精米所・遊び場・老舗の酒屋なども掲載しています</li>
          <li><w-icon>mdi mdi-check-bold</w-icon> ロマンの森共和国からも車で約１４分でお越しになれます</li>
          <li><w-icon>mdi mdi-check-bold</w-icon> 手作りの<router-link to="/service/nagasa-mai/detail/#thank">マグカップ</router-link>をプレゼントしております</li>
        </ul>
      </p>
      <p class="center">
        <w-icon>mdi mdi-arrow-right</w-icon>
        <a href="https://jmty.jp/chiba/sale-food/article-ueoyq" target="_blank" rel="noopener noreferrer">
          ショップは こちらから
          <w-icon>mdi mdi-open-in-new</w-icon></a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>
